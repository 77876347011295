import React from 'react';
import {instanceOf} from 'prop-types';
import UserManager from '../../../Provider/Controller/user/UserManager';
import { Button, ButtonProps, Typography } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import { useTranslation } from 'react-i18next';
import {useUpdaterContext} from '@context/UpdaterContext';
import Tooltip from '../../Utils/Tooltip';
import {useAppContext} from '@context/AppContext';
import { AuthenticatedModalData } from './AuthenticateModal';
import { useDialog } from '@context/ModalContext';
import useFollowLogic from 'src/Hooks/Logic/useFollowLogic';

const useStyles = makeStyles()(() => ({
    followButton: {
        display: 'flex',
        borderRadius: 6,
        textTransform: 'none'
    }
}));

interface FollowProps extends ButtonProps {
    user: UserManager;
    className?: string;
    callback?: () => void;
}

const Follow = (props: FollowProps): JSX.Element => {
    const {user, className, callback, ...rest} = props;
    const {classes, cx} = useStyles();
    const {authenticated, authUser} = useAppContext();
    const [openAuthenticated] = useDialog<AuthenticatedModalData>('AuthenticatedModal');
    const {t} = useTranslation('profile');
    const [update, updater] = useUpdaterContext();

    const [following, handleFollow] = useFollowLogic(user, () => {
        if (callback) {
            callback();
        }

        updater();
    });

    const [disabled, disableReason] = ((): [boolean, string] => {
        // User is banned and not following it
        if (user.data.banned && !following) {
            return [true, t('common:USER_BANNED_WARNING', {username: user.data.username})];
        }

        if (authUser && user && user.data.id === authUser.data.id) {
            return [true, t('common:CANNOT_FOLLOW_YOURSELF')];
        }

        return [false, ''];
    })();

    return (
        <>
            <Tooltip title={disableReason} disabled={!disabled}>
                <Button
                    key={update}
                    aria-label='follow/unfollow'
                    disabled={disabled}
                    className={cx(classes.followButton, className)}
                    variant={following ? 'outlined' : 'contained'}
                    color={'primary'}
                    fullWidth
                    disableElevation={true}
                    onClick={(e) => authenticated ? handleFollow(e) : openAuthenticated({type: 'follow', user})}
                    {...rest}
                >
                    <Typography variant="h2" fontWeight={700}>
                        {following ? t('FOLLOWING') : t('FOLLOW')}
                    </Typography>
                </Button>
            </Tooltip>
        </>
    );
};

Follow.propTypes = {
    user: instanceOf(UserManager).isRequired
};

export default Follow;

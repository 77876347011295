import { useTheme } from '@mui/material';
import {REGEX_LINE_BREAK} from '../../Constants';
import replaceAll from 'string.prototype.replaceall';

interface OptionalOptions {
    font?: string
    fontSize?: string
    fontWeight?: string
    lineHeight?: string
    width?: string
    wordBreak?: string
}

interface Options {
    font: string
    fontSize: string
    fontWeight: string
    lineHeight: string
    width: string
    wordBreak: string
}

interface Size {
    width: number
    height: number
}

function createDummyElement(text: string, options: Options, debug: boolean): HTMLElement {
    const element = document.createElement('div');
    const texts = replaceAll(text, REGEX_LINE_BREAK, '<sf-line-break>').split('<sf-line-break>');

    texts.forEach(t => {
        if (t) {
            element.appendChild(document.createTextNode(t));
        }

        element.appendChild(document.createElement('br'));
    });

    element.style.fontFamily = options.font;
    element.style.fontSize = options.fontSize;
    element.style.fontWeight = options.fontWeight;
    element.style.lineHeight = options.lineHeight;
    element.style.position = 'absolute';
    element.style.visibility = debug ? 'visible' : 'hidden';
    element.style.left = debug ? '0' : '-999px';
    element.style.top = debug ? '500px' : '-999px';
    element.style.width = options.width;
    element.style.height = 'auto';
    element.style.wordBreak = options.wordBreak;
    
    // Needed to correctly render line breaks
    element.style.whiteSpace = 'pre-line';

    element.style.backgroundColor = 'yellow';
    element.style.color = 'black';

    document.body.appendChild(element);

    return element;
}

function destroyElement(element: HTMLElement): void {
    element.parentNode.removeChild(element);
}

const cache = {};

export type CalcTextHeight = (width: number, text: string, options?: OptionalOptions, debug?: boolean) => Size;

const useCalcTextHeight = (): CalcTextHeight => {

    const theme = useTheme();

    return (width: number, text: string, options: OptionalOptions = {}, debug = false): Size => {
        const cacheKey = text + '-' + width;

        if (cache[cacheKey]) {
            // return cache[cacheKey];
        }

        // prepare options
        options.font = theme.typography.fontFamily;
        options.fontSize = options.fontSize || '0.75rem';
        options.fontWeight = options.fontWeight || 'normal';
        options.lineHeight = options.lineHeight || 'normal';
        options.width = `${width}px`;
        options.wordBreak = options.wordBreak || 'break-word';

        const element = createDummyElement(text, options as Options, debug);

        const size = {
            width: element.offsetWidth,
            height: element.offsetHeight,
        };

        if (!debug) {
            destroyElement(element);
        }

        cache[cacheKey] = size;

        return size;
    };
};

export default useCalcTextHeight;
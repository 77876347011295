import PostLayout from '@components/NewPost/Layout';
import Box from '@mui/material/Box';
import React from 'react';
import {Skeleton} from '@mui/material';
import {SPACING_FACTOR} from '@theme/theme';

export const getPostSkeletonHeight = (containerWidth: number): number => 63 + SPACING_FACTOR + containerWidth*0.8;

const PostSkeleton = (): JSX.Element => {
    return (
        <div>
            <PostLayout skeleton>
                <>
                    <Box>
                        <Skeleton variant={'text'} width={100}/>

                        <Skeleton variant={'text'} width={'100%'}/>
                        <Skeleton variant={'text'} width={'80%'}/>
                    </Box>

                    <Box mt={1}>
                        <Skeleton variant={'rectangular'} style={{borderRadius: 10, transform: 'none', paddingBottom: '80%'}}/>
                    </Box>
                </>
            </PostLayout>
        </div>
    );
};

export default PostSkeleton;
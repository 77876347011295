import {PaginationHook} from '@provider/Pagination';
import PostManager from '@provider/Controller/post/PostManager';
import {MutableRefObject, useEffect, useRef, useState} from 'react';
import {List} from 'react-virtualized';

const useScrollToPost = (pagination: PaginationHook<PostManager>, listRef: MutableRefObject<List>, postId: string, offset?: number): void => {
    const postIdRef = useRef<string>(postId);

    const [scrollIndex, setScrollIndex] = useState(null);

    useEffect(() => {
        postIdRef.current = postId;
    }, [postId]);

    // If not found after pagination marked as loaded don't bother scrolling to it
    // since it'll just cause confusion for the user
    useEffect(() => {
        if (pagination.contentLoaded && postIdRef.current) {
            postIdRef.current = null;

            const idx = pagination.data.findIndex(p => p instanceof PostManager && p.data.id === postId);

            if (idx > 0) {
                setScrollIndex(idx);
            }
        }
    }, [pagination.contentLoaded, postIdRef.current]);

    useEffect(() => {
        if (scrollIndex && pagination.data.length >= scrollIndex && listRef.current) {
            const target = listRef.current.getOffsetForRow({
                alignment: 'start',
                index: scrollIndex
            });

            if (target !== 0) {
                setTimeout(() => {
                    window.scrollTo({top: target + (offset || 0), behavior: 'smooth'});
                }, 500);
            }

        }
    }, [scrollIndex, listRef.current]);
};

export default useScrollToPost;
import {useAppContext} from '@context/AppContext';
import {useEffect, useMemo} from 'react';

const cacheKey = (key: string) => `cachePersistEffect-${key}`;

const useCachePersistEffect = <T>(key: string, callback: () => T, enabled = true, deps = []): T => {
    const {cache} = useAppContext();

    const initialData = useMemo(() => cache.get(cacheKey(key)) as T, []);

    // Register events for scroll cache
    useEffect(() => {
        if (!enabled) {
            return;
        }

        // Get current value
        const value: T = callback();

        return () => {
            if (!enabled) {
                return;
            }

            // Persist on cache
            cache.set(cacheKey(key), value);
        };
    }, [enabled, ...deps]);

    return initialData;
};

export default useCachePersistEffect;
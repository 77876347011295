import React, {useEffect, useMemo, useRef, useState} from 'react';
import PostManager from '@provider/Controller/post/PostManager';
import withParentDimensions, {InjectedDimensions, WithWrapperRefProps} from '@components/Utils/withParentDimensions';
import usePostHeightCalculator from '@components/NewPost/usePostHeightCalculator';
import useCalcTextHeight from '@hooks/Utils/useCalcTextHeight';
import NewPost, {PostOpts} from '@components/NewPost';
import ScrollFeed from '@components/Feed/ScrollFeed';
import {List, WindowScroller} from 'react-virtualized';
import {PaginationHook} from '@provider/Pagination';
import PostSkeleton, {getPostSkeletonHeight} from '@components/NewPost/PostSkeleton';
import Box from '@mui/material/Box';
import {Divider} from '@mui/material';
import {SPACING_FACTOR} from '@theme/theme';
import useScrollToPost from '@components/VirtualizedPostFeed/useScrollToPost';
import {useEventBus} from '../../EventBus/EventBus';
import useCachePersistEffect from '../../Utils/useCachePersistEffect';
import {useAppContext} from '@context/AppContext';

export interface VirtualizedRow {
    renderer: (props: {
        index: number,
        key: string,
        style: React.CSSProperties
    }) => JSX.Element;
    height: (props: {index: number}) => number;
}

export interface VirtualizedPostFeedInitialScroll {
    postId?: string;
    slideIdx?: (id: string) => number|null;
    offset?: number;
}

interface VirtualizedPostFeedPropsBase extends WithWrapperRefProps, InjectedDimensions {
    pagination: PaginationHook<PostManager|VirtualizedRow>;
    withPinned?: boolean;
    endMessage?: string;
    update?: number;
    postOpts?: PostOpts;
}

interface VirtualizedPostFeedPropsWithInitialScroll extends VirtualizedPostFeedPropsBase {
    initialScroll: VirtualizedPostFeedInitialScroll,
    saveScrollState?: never;
    feedKey?: never;
}

interface VirtualizedPostFeedPropsWithSaveScrollState extends VirtualizedPostFeedPropsBase {
    initialScroll?: never;
    saveScrollState: boolean;
    // TODO: key should be mandatory
    feedKey: string;
}

type VirtualizedPostFeedProps = VirtualizedPostFeedPropsWithInitialScroll | VirtualizedPostFeedPropsWithSaveScrollState;

/**
 * This hook encapsulates the read more logic to be usable many times.
 * We need this in a hook because we need two different states to control the read more of the actual post
 * and the read more of the repost (if any).
 *
 * @param nbPosts Length of the posts array
 * @returns The read more array and a helper function to update read more state of a given index.
 */
const useReadMoreArr = (nbPosts: number): [boolean[], (idx: number, newSate: boolean) => void] => {
    const [readMoreArr, setReadMoreArr] = React.useState<boolean[]>(new Array(nbPosts).fill(false));

    // Allocates space for new posts
    // TODO: Never unallocates for removed posts
    React.useEffect(() => {
        setReadMoreArr(p => [...p, ...new Array(Math.max(nbPosts - p.length, 0)).fill(false)]);
    }, [nbPosts]);

    const updateReadMore = (idx, newState) => setReadMoreArr(p => {
        const newArr = [...p];
        newArr[idx] = newState;

        return newArr;
    });

    return [readMoreArr, updateReadMore];
};

const ComponentContent = (props: VirtualizedPostFeedProps): JSX.Element => {
    const {feedKey, pagination, width, endMessage, withPinned, initialScroll, update, saveScrollState = false, postOpts} = props;

    const itemCount = pagination.hasNext ? pagination.data.length + 1 : pagination.data.length;

    const {authUser, drmAvailable} = useAppContext();

    const listRef = useRef<List>();

    const calcTextHeight = useCalcTextHeight();
    const calculatePostHeight = usePostHeightCalculator(calcTextHeight, width, drmAvailable, withPinned);

    // Recalculate when DRM availability changes
    useEffect(() => {
        if (!drmAvailable) {
            recalculateHeights();
        }
    }, [drmAvailable]);

    const [readMore, setReadMore] = useReadMoreArr(pagination.data.length);
    const [readMoreReposts, setReadMoreReposts] = useReadMoreArr(pagination.data.length);
    const [visiblePost, setVisiblePost] = useState<string>();

    const previousVisiblePost = useCachePersistEffect<string>(`virtualizedPostFeed-${feedKey}`, () => visiblePost, saveScrollState, [visiblePost]);

    // Calculate initial scroll to post
    const scrollTo = initialScroll && initialScroll.postId || previousVisiblePost || undefined;

    // Scroll to initial post, if any
    useScrollToPost(pagination as PaginationHook<PostManager>, listRef, scrollTo, initialScroll && initialScroll.offset);

    const observer = useMemo(() => {
        // No need for intersection observer if we don't need to save scroll state
        if (!saveScrollState) {
            return undefined;
        }

        return new IntersectionObserver((entries) => {
            for (const entry of entries) {
                if (entry.intersectionRatio >= 0.9) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const id = entry.target && entry.target.dataset && entry.target.dataset.id;

                    if (id) {
                        setVisiblePost(id);
                    }
                }
            }
        }, {threshold: [0.8, 0.9, 1]});
    }, [saveScrollState]);

    const recalculateHeights = (index?: number) => {
        if (listRef && listRef.current) {
            listRef.current.recomputeRowHeights(index);
            listRef.current.forceUpdate();
        }
    };

    useEffect(() => {
        if (update) {
            recalculateHeights();
        }
    }, [update]);

    useEventBus([{
        id: 'update-virtualized-post-feed',
        events: ['sugarfans.post.ready'],
        callback: () => {
            recalculateHeights();
        }
    }]);

    const toggleReadMore = (repost: boolean, index: number, value: boolean) => {
        if (repost) {
            setReadMoreReposts(index, value);
        }
        else {
            setReadMore(index, value);
        }

        recalculateHeights(index);
    };

    const getItemSize = ({index}) => {
        if (!pagination.data[index]) {
            return getPostSkeletonHeight(width);
        }

        const divider = index === 0 ? SPACING_FACTOR : SPACING_FACTOR*4 + 0.667;

        if (pagination.data[index] instanceof PostManager) {
            const post = pagination.data[index] as PostManager;
            const isMyPost = !!authUser && authUser.data.id === post.data.userId;

            return calculatePostHeight(post, readMore[index], readMoreReposts[index], isMyPost) + divider;
        }

        return (pagination.data[index] as VirtualizedRow).height({index}) + divider;
    };

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = index => !pagination.hasNext || index < pagination.data.length;

    // Render an item or a loading indicator.
    // eslint-disable-next-line react/prop-types
    const renderItem = ({ index, style, key }) => {
        if (!isItemLoaded(index)) {
            return <div key={key} style={style}><PostSkeleton/></div>;
        }

        const divider = index == 0 ? <Box mt={1}></Box> : <Box mt={2} mb={2}><Divider/></Box>;

        if (pagination.data[index] instanceof PostManager) {
            const post = pagination.data[index] as PostManager;

            return <div key={key} style={style}>
                {divider}

                <NewPost
                    observer={observer}
                    post={post}
                    position={index}
                    initialSlide={initialScroll && initialScroll.slideIdx ? initialScroll.slideIdx(post.data.id) : undefined}
                    opts={{
                        ...(postOpts || {}),
                        withPinned: withPinned
                    }}
                    controlledReadMore={[readMore[index], value => toggleReadMore(false, index, value as boolean)]}
                    controlledReadMoreRepost={[readMoreReposts[index], value => toggleReadMore(true, index, value as boolean)]}
                />
            </div>;
        }

        const renderer = (pagination.data[index] as VirtualizedRow).renderer({index, style, key});

        return <div key={key} style={style}>
            {divider}

            {renderer}
        </div>;
    };

    // Wait for parent to give its width
    if (width === 0) {
        return <div/>;
    }

    // Hide if no elements and backend loaded
    if (pagination.contentLoaded && !pagination.hasNext && pagination.data.length === 0) {
        return <div/>;
    }

    // TODO: Fade in para posts
    return <WindowScroller>
        {({height, isScrolling, onChildScroll, scrollTop, registerChild}) =>
            <div>
                <ScrollFeed pagination={pagination} endMessage={endMessage || ''}>
                    <div ref={registerChild}>
                        <List
                            style={{willChange: 'unset'}}
                            ref={listRef}
                            autoHeight
                            height={height}
                            isScrolling={isScrolling}
                            onScroll={onChildScroll}
                            scrollTop={scrollTop}
                            noRowsRenderer={() => <div>
                                <Box mb={2}>
                                    <PostSkeleton/>
                                </Box>

                                <Box mb={2}>
                                    <PostSkeleton/>
                                </Box>

                                <Box>
                                    <PostSkeleton/>
                                </Box>
                            </div>}

                            width={width}
                            rowCount={itemCount}
                            rowRenderer={renderItem}
                            rowHeight={getItemSize}
                            // scrollToIndex={scrollToIdx}
                        />
                    </div>
                </ScrollFeed>
            </div>}
    </WindowScroller>;
};

const VirtualizedPostFeed = (props: VirtualizedPostFeedProps): JSX.Element => {
    return <ComponentContent {...props} />;
};

export default withParentDimensions(VirtualizedPostFeed);
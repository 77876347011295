import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Typography} from '@mui/material';
import {PaginationHook} from '../../Provider/Pagination';
import {makeStyles} from '@theme/makeStyles';
import {Theme} from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../../Context/ModalContext';
import { Box } from '@mui/system';

export interface ScrollFeedProps<Manager> {
    pagination: PaginationHook<Manager>;
    children: JSX.Element[] | JSX.Element;
    endMessage: string;
    withoutMarginBottom?: boolean;
    hideLoad?: boolean;
    inverse?: boolean;
    pullDownToRefresh?: boolean;
    threshold?: number;
    classes?: {
        root?: string;
        container?: string;
    },
    scrollTarget?: string
}

const useStyles = makeStyles()((theme: Theme) => ({
    center: {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
        width: '100%'
    },
    noMorePosts: {
        textAlign: 'center',
        margin: theme.spacing(2)
    },
    noMorePostsWithoutMarginBottom: {
        margin: 0
    }
}));

function ScrollFeed<Manager>(props: ScrollFeedProps<Manager>): JSX.Element {
    const {pagination, children, endMessage, withoutMarginBottom, hideLoad, pullDownToRefresh, inverse, threshold, classes: classesProps, scrollTarget} = props;
    const {classes, cx} = useStyles();
    const {t} = useTranslation('common');

    const {data: modalData} = useModalContext();

    const {hasNext, next, data, refresh, loading, contentLoaded} = pagination;

    return (
        <div style={{width: '100%'}} className={classesProps && classesProps.root}>
            <InfiniteScroll
                next={contentLoaded && next}
                hasMore={contentLoaded && (hasNext || loading)}
                loader={null}
                dataLength={data.length}
                refreshFunction={() => refresh(true, 1)}
                pullDownToRefresh={pullDownToRefresh && Object.values(modalData).every(d => !d.open)}
                releaseToRefreshContent={
                    !hideLoad && (
                        <Typography variant={'h3'} className={classes.center} color={'textSecondary'}>&#8593; {t('RELEASE_TO_REFRESH')}</Typography>
                    )
                }
                endMessage={
                    contentLoaded && <Typography variant={'h3'} className={cx(classes.noMorePosts, withoutMarginBottom ? classes.noMorePostsWithoutMarginBottom : '')} color={'textSecondary'}>{endMessage}</Typography>
                }
                scrollableTarget={scrollTarget}
                scrollThreshold={threshold}
                inverse={inverse}
                className={classesProps && classesProps.container || ''}
            >
                <Box sx={{overflowX: 'hidden'}}>
                    {children}
                </Box>
            </InfiniteScroll>
        </div>
    );
}

ScrollFeed.defaultProps = {
    withoutMarginBottom: false,
    hideLoad: false,
    pullDownToRefresh: false,
    threshold: 0.7,
    inverse: false
};

export default ScrollFeed;
import {CalcTextHeight} from '@hooks/Utils/useCalcTextHeight';
import {useReadMoreThreshold} from '@components/Markdown';
import {SPACING_FACTOR} from '@theme/theme';
import PostManager from '@provider/Controller/post/PostManager';
import {POST_HEADER_HEIGHT_PX} from '@components/NewPost/Header';
import {POST_ACTIONS_HEIGHT} from '@components/NewPost/PostActions';
import {getSliderConfigFromPost} from '@components/NewMediaSlider/utils';
import {POST_LAYOUT_AVATAR_WIDTH_MOBILE_PX, POST_LAYOUT_AVATAR_WIDTH_PX} from '@components/NewPost/Layout';
import {useThemeContext} from '@theme/ThemeContext';
import {POST_AVATAR_SIZE_PX} from '../../Constants';
import {POST_REPOST_BORDER_PX} from '@components/Post';
import {POST_HEADER_MARGIN_BOTTOM} from '@components/Post/PostHeader';
import UserManager from '@provider/Controller/user/UserManager';

type PostHeightCalculatorFunction = (post: PostManager, readMore: boolean, readMoreRepost: boolean, isMyPost: boolean) => number;

const usePostHeightCalculator = (calcTextHeight: CalcTextHeight, containerWidth: number, drmAvailable: boolean, withPinned = false): PostHeightCalculatorFunction => {
    const {isDesktop} = useThemeContext();

    return (post: PostManager, readMore: boolean, readMoreRepost: boolean, isMyPost: boolean) => calculatePostHeight(calcTextHeight, containerWidth, post, readMore, readMoreRepost, isDesktop, false, isMyPost, drmAvailable, withPinned);

    // return useMemo(() => calculatePostHeight(calcTextHeight, containerWidth, post, readMore, readMoreRepost, isDesktop, false), [post, isDesktop, readMore, readMoreRepost]);
};

const calculatePostHeight = (calcTextHeight: CalcTextHeight, containerWidth: number, post: PostManager, readMore: boolean, readMoreRepost: boolean, isDesktop: boolean, isRepost: boolean, isMyPost: boolean, drmAvailable: boolean, withPinned: boolean): number => {
    if (!post) {
        return 0;
    }

    const realWidth = isRepost ?
        // When calculating the inside post of a repost, we already have the valid container width
        containerWidth :
        // The actual content is on the right side of the avatar
        containerWidth - (isDesktop ? POST_LAYOUT_AVATAR_WIDTH_PX : POST_LAYOUT_AVATAR_WIDTH_MOBILE_PX);

    if (post.data.type === 'repost') {
        // Reposts have a border and a padding (left and right)
        const repostPaddings = POST_REPOST_BORDER_PX*2 + (post.repostPost ? SPACING_FACTOR*2 : 0);

        // A repost has a margin between the original post header and the repost
        const repostMargins = POST_HEADER_MARGIN_BOTTOM*SPACING_FACTOR;

        const repostContainerWidth = post.repostPost ? realWidth - repostPaddings : realWidth;

        const header = getPostHeaderHeight(calcTextHeight, repostContainerWidth, post, readMore, false);
        const repostHeight = post.repostPost ?
            calculatePostHeight(calcTextHeight, repostContainerWidth, post.repostPost, readMore, readMoreRepost, isDesktop, true, isMyPost, drmAvailable, withPinned) :
            calculateRepostUserHeight(post.repostUser, isDesktop, repostContainerWidth);

        // console.log('---- repostContainerWidth', repostContainerWidth);
        // console.log('---- repostHeight', repostHeight);
        // console.log('---- repostHeaderHeight', header);
        // console.log('---- repostPaddings', repostPaddings);
        // console.log('---- repostMargins', repostMargins);
        // console.log('---- TOTAL', header + repostHeight + repostPaddings + repostMargins);

        return header + repostHeight + repostPaddings + repostMargins;
    }

    const header = getPostHeaderHeight(calcTextHeight, realWidth, post, isRepost ? readMoreRepost : readMore, isRepost);
    const actions = POST_ACTIONS_HEIGHT;
    const media = getSliderConfigFromPost(realWidth, post, isMyPost, drmAvailable).height + SPACING_FACTOR;
    const sliderDots = post.data.files.length > 1 && post.data.visibilityType === 'free' ? 6 + SPACING_FACTOR : 0;
    const unlockButton = post.data.private && post.data.price && drmAvailable ? 40 : 0;
    const pinnedHeight = withPinned && post.data.pinned ? 23 : 0;

    // console.log('-- postHeight header', header);
    // console.log('-- postHeight actions', actions);
    // console.log('-- postHeight media', media);
    // console.log('-- postHeight sliderDots', sliderDots);
    // console.log('-- postHeight TOTAL', header + actions + media + sliderDots);

    return header + actions + media + sliderDots + unlockButton + pinnedHeight;
};

const calculateRepostUserHeight = (user: UserManager, isDesktop: boolean, containerWidth: number): number => {
    if (!user) {
        return 0;
    }

    const hasFiles = user.data.visitorStatus.postsCount > 0;

    const header = isDesktop ? 190 : 100;
    const info = isDesktop ? 138 : 135;
    const imageWidth = hasFiles ? containerWidth/3 : 0; // 261

    return header + info + imageWidth;
};

const getPostContentTextHeight = (calcTextHeight: CalcTextHeight, containerWidth: number, text: string, readMore: boolean) => {
    if (!text || !text.length) {
        return 0;
    }

    const textOpts = {fontSize: '0.813rem', lineHeight: 'normal'};
    const readMoreThreshold = useReadMoreThreshold(text, true);
    const readMoreButtonHeight = 20 + SPACING_FACTOR;

    if (text.length > readMoreThreshold) {
        return readMore ?
            // Read more enabled -> text height + read more button height
            calcTextHeight(containerWidth, text, textOpts).height + readMoreButtonHeight :

            // Read more disabled -> text height (trimmed with read more threshold) + read more button height
            calcTextHeight(containerWidth, text.substring(0, readMoreThreshold) + '...', textOpts).height + readMoreButtonHeight;
    }

    return calcTextHeight(containerWidth, text, textOpts).height;
};

const getPostHeaderHeight = (calcTextHeight: CalcTextHeight, containerWidth: number, post: PostManager, readMore: boolean, isRepost: boolean): number => {
    const text = getPostContentTextHeight(calcTextHeight, containerWidth, post.data.content || '', readMore);

    // On reposts, the avatar + username is above the text, not on the side
    if (isRepost) {
        return POST_AVATAR_SIZE_PX + text + 5;
    }

    return POST_HEADER_HEIGHT_PX + text;
};

export default usePostHeightCalculator;
